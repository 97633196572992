/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    ul: "ul",
    li: "li",
    p: "p",
    h4: "h4"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, {
    id: "環境",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E7%92%B0%E5%A2%83",
    "aria-label": "環境 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "環境"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "ubuntu: 17.04"), "\n", React.createElement(_components.li, null, "nodebrew: 0.9.7"), "\n"), "\n", React.createElement(_components.h3, {
    id: "nodeのインストールに失敗する",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#node%E3%81%AE%E3%82%A4%E3%83%B3%E3%82%B9%E3%83%88%E3%83%BC%E3%83%AB%E3%81%AB%E5%A4%B1%E6%95%97%E3%81%99%E3%82%8B",
    "aria-label": "nodeのインストールに失敗する permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "nodeのインストールに失敗する"), "\n", React.createElement(_components.p, null, "pythonコマンドで3系が起動するように設定しているとき"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\">python\nPython <span class=\"token number\">3.6</span>.2 <span class=\"token operator\">|</span>Continuum Analytics, Inc.<span class=\"token operator\">|</span> <span class=\"token punctuation\">(</span>default, Jul <span class=\"token number\">20</span> <span class=\"token number\">2017</span>, <span class=\"token number\">13</span>:51:32<span class=\"token punctuation\">)</span> \n<span class=\"token punctuation\">[</span>GCC <span class=\"token number\">4.4</span>.7 <span class=\"token number\">20120313</span> <span class=\"token punctuation\">(</span>Red Hat <span class=\"token number\">4.4</span>.7-1<span class=\"token punctuation\">)</span><span class=\"token punctuation\">]</span> on linux\nType <span class=\"token string\">\"help\"</span>, <span class=\"token string\">\"copyright\"</span>, <span class=\"token string\">\"credits\"</span> or <span class=\"token string\">\"license\"</span> <span class=\"token keyword\">for</span> <span class=\"token function\">more</span> information.\n<span class=\"token operator\">>></span><span class=\"token operator\">></span> </code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "nodeをインストールすると失敗する"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\">nodebrew <span class=\"token function\">install</span> v6.11\nFetching: https://nodejs.org/dist/v6.11.2/node-v6.11.2.tar.gz\n<span class=\"token comment\">######################################################################## 100.0%</span>\nPlease use either Python <span class=\"token number\">2.6</span> or <span class=\"token number\">2.7</span>:\n\n  /usr/bin/python2 ./configure <span class=\"token parameter variable\">--prefix</span><span class=\"token operator\">=~</span>/.nodebrew/node/v6.11.2</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "python2系じゃないとだめらしい"), "\n", React.createElement(_components.h4, {
    id: "対処法１install-binaryにする",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E5%AF%BE%E5%87%A6%E6%B3%95%EF%BC%91install-binary%E3%81%AB%E3%81%99%E3%82%8B",
    "aria-label": "対処法１install binaryにする permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "対処法１（install-binary）にする"), "\n", React.createElement(_components.p, null, "installではなくinstall-binaryを使ったらインストールに成功した"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\">nodebrew install-binary v6.11\n\nhttps://nodejs.org/dist/v6.11.2/node-v6.11.2.tar.gz\n<span class=\"token comment\">######################################################################## 100.0%</span>\nInstalled successfully</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.h4, {
    id: "対処法2デフォルトをpython2系にする",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E5%AF%BE%E5%87%A6%E6%B3%952%E3%83%87%E3%83%95%E3%82%A9%E3%83%AB%E3%83%88%E3%82%92python2%E7%B3%BB%E3%81%AB%E3%81%99%E3%82%8B",
    "aria-label": "対処法2デフォルトをpython2系にする permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "対処法2（デフォルトをpython2系にする）"), "\n", React.createElement(_components.p, null, "試してはないですが、↓記事のようにすればできるのでは"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://please-sleep.cou929.nu/nodejs-needs-python-26-or-27.html"
  }, "node.js の build には python2.6 or 2.7 が必要")), "\n"), "\n", React.createElement(_components.h4, {
    id: "参考",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E5%8F%82%E8%80%83",
    "aria-label": "参考 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "参考"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://tech.pjin.jp/blog/2016/12/03/how_to_use_nodebrew/"
  }, "nodebrew 〜 Node.jsのバージョンを管理する for Mac 〜")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
